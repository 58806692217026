import numeral from 'numeral';
import moment from "moment";

export const formatPrice = (price: string): void => {
  return numeral(price).format("$0,0.00"); 
};

type returnType = string;
export const formatDate = (date: string | null): returnType => {
  return moment(date, "YYYY-MM-DD H:m:s").format('MMM Do, Y')
};

type returnDateTimeType = string;
export const formatDateTime = (date: string | null): returnDateTimeType => {
  return moment(date, "YYYY-MM-DD H:m:s").format('MMM Do, Y LT')
};

export const formatText = (string: string) => {
    return string.toString().replace(/-/g, ' ').replace(/_/g, ' ').replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(?: |\b)(\w)/g, function(key) { 
    return key.toUpperCase()
    });
}